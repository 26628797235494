"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SetTimerCtrl = void 0;
const alarmclock_countdown_timer_1 = require("@src/shared/alarmclock~countdown~timer");
const datetime_utils_1 = require("@src/shared/datetime_utils");
class SetTimerCtrl extends alarmclock_countdown_timer_1.BaseSetCtrl {
    constructor($scope, $locale, $timeout, $location, $element, ConfigService, SoundService, UploadDataUrl) {
        super($scope, $locale, $timeout, $location, $element, ConfigService, SoundService, UploadDataUrl);
        this.$scope = $scope;
        this.$locale = $locale;
        this.$timeout = $timeout;
        this.$location = $location;
        this.$element = $element;
        this.ConfigService = ConfigService;
        this.SoundService = SoundService;
        this.UploadDataUrl = UploadDataUrl;
        this.sounds = this.SoundService.getOptions();
        this.played = false;
        this.timerData = {
            // timer
            h: (0, datetime_utils_1.format2Digit)(this.$modalScope.data.count / (60 * 60)),
            m: (0, datetime_utils_1.format2Digit)((this.$modalScope.data.count / 60) % 60),
            s: (0, datetime_utils_1.format2Digit)(this.$modalScope.data.count % 60),
            onZero: this.$modalScope.data.onZero,
            title: this.$modalScope.data.title,
            showMessage: this.$modalScope.data.showMessage,
            soundMessage: true,
            soundRepeat: this.$modalScope.data.soundRepeat,
            sound: this.$modalScope.data.sound
        };
        this.hours = [...Array(24).keys()].map((item) => {
            return {
                value: item,
                label: (item / 100).toFixed(2).toString().slice(2)
            };
        });
        this.sm = [...Array(60).keys()].map((item) => {
            return {
                value: item,
                label: (item / 100).toFixed(2).toString().slice(2)
            };
        });
        this.showDate = false;
        this.showTime = false;
    }
    $onInit() {
        super.$onInit();
        this.$scope.$watch('$ctrl.timerData', (timerData, oldTimerData) => {
            var _a, _b;
            if (((_a = timerData.sound) === null || _a === void 0 ? void 0 : _a.label) != ((_b = oldTimerData.sound) === null || _b === void 0 ? void 0 : _b.label)) {
                if (this.played) {
                    this.SoundService.soundbox.stop_all();
                    this.$timeout(() => {
                        this.play();
                    }, 100);
                }
                else {
                    // this.played = true;
                }
            }
            const hours = timerData.h ? timerData.h.value : 23;
            const minutes = timerData.m ? timerData.m.value : 0;
            const seconds = timerData.s ? timerData.s.value : 0;
            Object.assign(this.$modalScope.data, {
                value: hours * 3600 + minutes * 60 + seconds,
                count: hours * 3600 + minutes * 60 + seconds,
                // datetime: null,
                onZero: timerData.onZero,
                title: timerData.title,
                showDate: timerData.showDate,
                showMessage: timerData.showMessage,
                soundMessage: timerData.soundMessage,
                soundRepeat: timerData.soundRepeat,
                sound: timerData.sound,
            });
        }, true);
    }
    setDebugTime() {
        Object.assign(this.$modalScope.data, {
            value: 5,
            count: 5,
            // datetime: null,
            onZero: this.timerData.onZero,
            title: this.timerData.title,
            showDate: this.timerData.showDate,
            showMessage: this.timerData.showMessage,
            soundMessage: this.timerData.soundMessage,
            soundRepeat: this.timerData.soundRepeat,
            sound: this.timerData.sound,
        });
        this.close('start');
    }
}
exports.SetTimerCtrl = SetTimerCtrl;
SetTimerCtrl.$inject = [
    '$scope',
    '$locale',
    '$timeout',
    '$location',
    '$element',
    'ConfigService',
    'SoundService',
    'UploadDataUrl'
];
