async function loadMomentLocale(languageCode) {
    let _languageCode = languageCode.slice(0, 2)

    if (_languageCode == 'tl') {
       _languageCode = 'tl-ph'
    } else if (_languageCode == 'no') {
        _languageCode = 'nb'
    } else if (_languageCode == 'zh') {
        _languageCode = 'zh-cn'
    }
    return import(
        /* webpackInclude: /\/(az|id|ms|ca|da|de|et|en|es|el|tl-ph|fr|hr|it|lv|lt|hu|nl|nb|nn|pl|pt|ro|sq|sk|sl|sr|fi|sv|vi|tr|cs|bg|mk|ru|uk|ar|fa|bn|he|ur|hi|th|ka|zh-cn|zh-tw|ja|ko)\.js$/ */
        /* webpackChunkName: "moment_locale" */
        /* webpackMode: "lazy-once" */
        /* webpackPreload: true */
        `moment/locale/${_languageCode}.js`).then(function () {return _languageCode})
}

export {loadMomentLocale};