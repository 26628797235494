"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.format2Digit = void 0;
const format2Digit = (a) => {
    let b = a;
    if (!a) {
        if (a != 0) {
            b = 0;
        }
    }
    const value = Math.floor(b);
    return {
        value: value,
        label: (value / 100.0).toFixed(2).toString().slice(2)
    };
};
exports.format2Digit = format2Digit;
