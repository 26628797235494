"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PauseComp = exports.ConfirmComp = exports.AlertComp = exports.AlertComponentCtrl = void 0;
const ng = window.angular;
class AlertComponentCtrl {
    constructor($scope, $locale) {
        this.$scope = $scope;
        this.$locale = $locale;
        this.$modalScope = this.$scope.$parent;
    }
    $onInit() {
        if (this.$modalScope.__setupController) {
            this.$modalScope.__setupController.bind(this)();
        }
    }
    close(result) {
        return __awaiter(this, void 0, void 0, function* () {
            const instanceKey = this.$modalScope.$random ? this.$modalScope.id + '_' + this.$modalScope.$random : this.$modalScope.id;
            if (this.$modalScope.test)
                result = 'back';
            yield this.modalInstance.close(instanceKey, result);
        });
    }
    closeAll(result) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.$modalScope.test)
                result = 'back';
            yield this.modalInstance.close('__all__', result);
        });
    }
}
exports.AlertComponentCtrl = AlertComponentCtrl;
AlertComponentCtrl.$inject = ['$scope', '$locale'];
exports.AlertComp = {
    bindings: {
        'modalInstance': '='
    },
    template: ['$attrs', ($attrs) => {
            return $attrs.modalTemplate ? unescape($attrs.modalTemplate) : require("./alert/alert-default.ng.html");
        }],
    controller: AlertComponentCtrl,
    controllerAs: '$ctrl'
};
exports.ConfirmComp = {
    bindings: {
        'modalInstance': '='
    },
    template: ['$attrs', ($attrs) => {
            return $attrs.modalTemplate ? unescape($attrs.modalTemplate) : require("./confirm/confirm-default.ng.html");
        }],
    controller: AlertComponentCtrl,
    controllerAs: '$ctrl'
};
exports.PauseComp = {
    bindings: {
        'modalInstance': '='
    },
    template: ['$attrs', ($attrs) => {
            return $attrs.modalTemplate ? unescape($attrs.modalTemplate) : require("./pause/pause-default.ng.html");
        }],
    controller: AlertComponentCtrl,
    controllerAs: '$ctrl'
};
